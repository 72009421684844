import * as THREE from 'three'
import gsap from 'gsap'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import GUI from 'lil-gui'

/**
 * GUI and Parameters Setup
 */
const gui = new GUI()
const parameters = {
    materialColor: '#caf0fe'
}
gui
    .addColor(parameters, 'materialColor')
    .onChange(() =>{
        material.color.set(parameters.materialColor)
        particlesMaterial.color.set(parameters.materialColor)
    });

/**
 * Canvas and Scene Setup
 */
const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()

/**
 * Textures and Fonts Setup
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/8.png')
const gradientTexture = textureLoader.load('textures/gradients/5.jpg')
gradientTexture.magFilter = THREE.NearestFilter

const fontLoader = new FontLoader()
/**fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'Ahmad Mahagna',
            {
                font: font,
                size: 0.6,
                depth: 0.2,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
            }
        )
        textGeometry.center()
        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        const text = new THREE.Mesh(textGeometry, textMaterial)
        scene.add(text)
    }
)*/

/**
 * Meshes Setup
 */
const objectDistance = 4
const material = new THREE.MeshToonMaterial({
     color: parameters.materialColor,
     gradientMap: gradientTexture
})

const mesh1 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), material)
const mesh2 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), material)
const mesh3 = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), material)

const sectionMeshes = [mesh1, mesh2, mesh3]

for (let mesh of sectionMeshes) {
    mesh.position.z += 0
}
mesh1.position.x += 1.5
mesh2.position.x -= 1.5
mesh3.position.x += 1.5
mesh2.position.y -= objectDistance
mesh3.position.y -= objectDistance * 2
scene.add(mesh1, mesh2, mesh3)

/**
 * Particles Setup
 */
const particlesCount = 1000
const positions = new Float32Array(particlesCount * 3)

for (let i = 0; i < particlesCount; i++) {
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectDistance * 0.5 - Math.random() * objectDistance * sectionMeshes.length
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
// materials for particles
const particlesMaterial = new THREE.PointsMaterial({
    size: 0.03,
    color: parameters.materialColor,
    sizeAttenuation: true,
})

const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Lights Setup
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)

/**
 * Camera, Sizes, and Renderer Setup
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 5
cameraGroup.add(camera)

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



////    EVENTS  ////
// init Cursor
const cursor = {}
cursor.x = 0
cursor.y = 0
// Mouse Move Event
window.addEventListener('mousemove', (event) =>
    {
        cursor.x = event.clientX / sizes.width - 0.5
        cursor.y = event.clientY / sizes.height - 0.5
    
        //console.log(cursor)
    })

// Window Resize Event
window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// init scroll position
let scrollY = window.scrollY
let currentSection = 0
// Scroll Event
window.addEventListener('scroll', () => {
    scrollY = window.scrollY

    const newSection = Math.round(scrollY / sizes.height)

    if (newSection != currentSection) {
        
        currentSection = newSection

        gsap.to(
            sectionMeshes[currentSection].rotation,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=1.5',
            }
        )
        //console.log("changed") // Debug scroll position
    }
})

/**
 * Animation Loop
*/
// Modify the tick function
const clock = new THREE.Clock()
let previousTime = 0

// Modify the tick function
const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Animate camera
    camera.position.y = - scrollY / sizes.height * objectDistance;

    const parallaxX = cursor.x * 0.5;
    const parallaxY = - cursor.y * 0.5;

    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 2 * deltaTime;
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 2 * deltaTime;

    // Render scene
    renderer.render(scene, camera);

    // Animate mesh rotation
    for (let mesh of sectionMeshes){
        mesh.rotation.x += deltaTime * 0.1;
        mesh.rotation.y += deltaTime * 0.12;
    }

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

tick();

